.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-container {
    background: url("https://cdn.snapplify.com/2.0.29/img/city_bg.png") no-repeat fixed 0 35%;
    background-size: cover;
    padding: 30px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.main-content-box {
    margin: 50px auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background: white;
    padding: 25px;
}

.robot {
    height: 450px;
    margin-left: 60px;
    width: auto;
}

@media screen and (max-width: 1200px) {
    .robot {
        display: none;
    }
}

form > .form-control+.form-control {
    margin-top: .5rem;
}


